import { Location } from '@reach/router'
import React, { ReactNode, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '../../components/basic/Button'
import Grid, { GridSpacing } from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Container from '../../components/basic/Container'
import StatsProgress from '../../components/complex/StatsProgress'
import MainLayout from '../../components/MainLayout'
import EncouragePayment from '../../components/EncouragePayment'
import MaturityTallies from '../../components/MaturityTallies'
import ThanksForPayment from '../../components/ThanksForPayment'

import auth from '../../utils/auth'

import api from '../../backend'

import styles from './index.module.scss'

interface FormData {
  feedback: string;
}

const Centered = (props: { children: any}) => <div className={styles.centered}>{props.children}</div>

interface IActionCardProps {
  children: any
  to?: string
  action: string
  style?: 'highlight' | 'normal'
}

const ActionCard = (props: IActionCardProps) => {
  const style = styles.actionCard + ' ' + (props.style==='highlight'?styles.actionCardHighlight:styles.actionCardNormal)

  return (<div className={style}>
    <div className={styles.actionCardText}>
      {props.children}
    </div>
    <div>
      {props.to!==undefined && <Button to={props.to} buttonStyle='ghost' disabled={props.to===undefined}>{props.action}</Button>}
    </div>
  </div>)
}

const UserPage = () => {
  if (!auth.isAuthenticated()) {
    auth.login()
    return <p>Leite zum Login weiter...</p>
  }

  // feedback handling
  const feedbackLoading = [ { message: 'Feedback wird geladen...' } ]
  // const [feedbackMessage, setFeedbackMessage] = useState<string>('');
  const [feedback, setFeedback] = useState<{ message: string }[]>(feedbackLoading);
  const { handleSubmit, register } = useForm<FormData>();
  const onSubmit = handleSubmit((data: FormData, e: any) => {
    // setFeedbackMessage('');
    e.target.reset()
    _applyFeedback(api.postFeedback(data))
  })
  const _applyFeedback = (promise: any) => {
    promise.then((response: any) => {
      if (response.result === 'ok') {
        setFeedback(response.feedback)
      }
      else {
        setFeedback(feedbackLoading)
      }
    }).catch(() => {
      setFeedback([ { message: 'Fehler beim Laden vom Feedback' } ])
    })
  }

  const [progress, setProgress] = useState<any>()
  const [paid, setPaid] = useState<boolean>(true)

  useEffect(() => {
    api.stats()
      .then(data => {
        const p: { [index:string]: any } = {}
        const keys = Object.keys(data.progress).filter(e => e.startsWith('n'))
        for (const list of keys) {
          p[list] = {
            learning: Math.round(data.progress[list].learning / data.progress[list].total * 100),
            mature: Math.round(data.progress[list].mature / data.progress[list].total * 100)
          }
        }
        setProgress({ progress: p, 
          total: data.totalLearned, 
          readyForReview: data.readyForReview, 
          readyForEarlyReview: data.readyForEarlyReview,
          maturity: data.maturity })
        setPaid(data.user.level !== 'free')
      })
      .catch(data => {
        if (data.result === 'login needed') {
          console.error('login needed');
        }
      })
    _applyFeedback(api.getFeedback())
  }, [])

  const user = auth.getUser();

  const logout = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    auth.logout()
    e.preventDefault()
  }

  const actionReviewStyle = (!progress || progress.readyForReview <= 0) ? 'normal' : 'highlight'
  const actionLearnStyle = (!progress || actionReviewStyle === 'highlight') ? 'normal' : 'highlight'
  let actionReviewText: ReactNode = 'Du musst erst neue Vokabeln lernen bevor du sie hier wiederholen kannst.'
  let actionReviewEnabled = false

  if (progress) {
    if (progress.readyForReview > 0) {
      actionReviewText = <>
        <span className={styles.big}>{progress.readyForReview} Vokabel{progress.readyForReview!=='1'&&'n'}</span><br/>
        {progress.readyForReview!=='1'?'sind':'ist'} bereit zum Auffrischen.
      </>
      actionReviewEnabled = true
    }
    else if (progress.readyForEarlyReview > 0) {
      actionReviewText = <><span className={styles.big}>Genug wiederholt</span><br/>Du kannst aber trotzdem üben.</>
      actionReviewEnabled = true
    }
    else if (progress.total > 0) {
      actionReviewText = 'Du hast heute schon alles gesehen. Lerne neue Vokabeln oder schau morgen wieder vorbei.'
    }
  }

  return (<MainLayout>
    <Container className={styles.userContainer}>
      <h2>Hallo</h2>
      { false && (paid ? <ThanksForPayment /> : <EncouragePayment /> )}
      <p>Deine Rückmeldung hilft mir den Trainer zu verbessern. Schreib mir doch einfach deine Wünsche. Danke ❤</p>
      <ul>
        <li>Nachricht auf <a href="https://www.instagram.com/jakobsjapanisch/">Instagram</a> oder <a href="https://www.facebook.com/jakobsjapanisch/">Facebook</a></li>
        <li>Mail an <a href="mailto:jakob@jakobsjapanisch.de">jakob@jakobsjapanisch.de</a></li>
      </ul>
      <h3>Modus: Kanji lesen</h3>
      <Grid container={true} spacing={2} className={styles.kanjiReadActions}>
        <Grid item={true} xs={12} sm={6}>
          <ActionCard to={actionReviewEnabled?'/meinbereich/wiederholen/':undefined} action='Wiederholen' style={actionReviewStyle}>
            {actionReviewText}
          </ActionCard>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <ActionCard to='/meinbereich/kanji-lesen/' action='Neu lernen' style={actionLearnStyle}>Lerne neue Vokabeln, die du noch nicht im Trainer gesehen hast.</ActionCard>
        </Grid>
      </Grid>
      <div className={styles.searchSection}>
        <p>Verwendest du andere Hilfsmittel zum Kanjilernen?</p>
        <p>Du kannst Kanji und Vokabeln, die du woanders gelernt hast auch gezielt deinem Lernstapel hinzufügen.</p>
        <Button to='/meinbereich/suchen/' buttonStyle='secondary'>Kanji oder Vokabel suchen</Button>
      </div>
      <h4>Fortschritt</h4>
      <MaturityTallies data={progress?progress.maturity:null}/>
      <h4>Fortschritt nach JLPT</h4>
      <p>
        Grün: Diese Wörter kannst du seit mindestens 30 Tagen.<br/>
        Gelb: Diese Wörter lernst du gerade. Sie sind entweder jünger als 30 Tage, oder du hattest sie falsch beantwortet.
      </p>
      {progress &&
      <Centered>
        {Object.keys(progress.progress).sort((a, b) => -a.localeCompare(b)).map(key => 
          <StatsProgress key={key} label={key.toUpperCase()} value={progress.progress[key].mature} valueBuffer={progress.progress[key].learning} />
        )}
      </Centered>}
      <h3>Modus: Kanji schreiben</h3>
      <p>Der Schreibenmodus speichert deinen Lernfortschritt (noch) nicht.</p>
      <Centered>
        <Button to="/kanji-schreiben/">Lernen</Button>
      </Centered>
      {false && 
      <div>
      <h3>Feedback</h3>
      <p>Hast du Verbesserungswünsche oder Fehlermeldungen? Über Rückmeldungen bin ich sehr dankbar! ❤</p>
      <form onSubmit={onSubmit}>
        <TextField
            name='feedback'
            inputRef={register}
            label='Dein Kommentar'
            multiline={true}
            fullWidth={true}
            rows={4}
            defaultValue=''
            variant='outlined'
          />
        <Button type='submit'>Senden</Button>
      </form>
      <h4>Bisherige Meldungen von dir</h4>
      </div>
      }
      {false && feedback && feedback.map((e, index) => <p key={index}>{e.message}</p>)}
      <h3>Konto</h3>
      <Centered>
        <a href="#logout" onClick={logout}>Ausloggen</a>
      </Centered>
    </Container>
  </MainLayout>)
};

export default UserPage
