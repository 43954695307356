import React from 'react'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress'

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 8,
      borderRadius: 4,
    },
    dashed: {
      animation: 0,
    },
    dashedColorPrimary: {
      background: '#f6f6f6',
      backgroundColor: '#f6f6f6',
    },
    bar1Buffer: {
      borderRadius: 5,
      backgroundColor: '#40D5AC',
    },
    bar2Buffer: {
      borderRadius: 5,
      backgroundColor: '#FFDC79',
    },
  }),
)(LinearProgress);

interface IStudyProgressProps {
  value: number;
  label: string;
  valueBuffer: number;
}

export default (props: LinearProgressProps & IStudyProgressProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={50}>
        <Typography variant="body1">{props.label}</Typography>
      </Box>
      <Box width="100%" mr={1}>
        <BorderLinearProgress variant="buffer" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.valueBuffer)}%`}</Typography>
      </Box>
    </Box>
  );
}
